<template>
  <div class="col-12" style="padding: 0px">
    <div class="card" style="padding: 0px">
      <div class="card-header">
        <h5>
          الموافق عليها
        </h5>
      </div>
      <div class="card-body" style="padding: 0px">
        <div class="col-12 table-responsive"  style="padding: 0px">
          <table class="table table-hover table-bordered table-sm table-striped" style="padding: 0px">
            <thead>
              <th>
                م
              </th>
              <th>
                الاسم
              </th>
              <th>
                الجوال
              </th>
              <th>
                تاريخ الاجابة
              </th>
              <th>
                خيارات
              </th>
            </thead>
            <tbody>
              <tr v-for="(form, i) in list" :key="form._id">
                <td>
                  {{ i+1 }}
                </td>
                <td>
                  {{ form.name }}
                </td>
                <td>
                  {{ form.phone }}
                </td>
                <td>
                  {{ form.end_date }}
                </td>
                <td>
                  <button style="border-radius: 0px; border:none" class="btn-sm btn btn-primary"  @click="current = form; header = current.info.contract.header; body = current.info.contract.body; footer = current.info.contract.footer;" v-b-modal.contract>
                    <i class="fa fa-edit"></i> العقد
                  </button>
                  <button style="border-radius: 0px; border: none; padding: 5px" class="btn-sm btn btn-success" v-if="form.signature">
                    <i class="fa fa-check"></i>
                    تم التوقيع
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <b-modal id="contract" title="العقد" hide-footer size="xl">
     <ckeditor :editor="editor" dir="rtl" v-model="header" :config="editorConfig"></ckeditor>
     <br>
     <ckeditor :editor="editor" dir="rtl" v-model="body" :config="editorConfig"></ckeditor>
     <br>
     <ckeditor :editor="editor" dir="rtl" v-model="footer" :config="editorConfig"></ckeditor>
     <div class="col-12 text-center g">
      <button class="btn btn-success" @click="save()">
        <i class="fa fa-save"></i> حفظ التعديلات
      </button>
      &nbsp;
      <button class="btn btn-warning" @click="printContract()">
        <i class="fa fa-print"></i> طباعة
      </button>
     </div><br>
     <table class="table table-hover table-bordered table-sm">
      <tbody>
        <tr>
          <td>
            {section}
          </td>
          <td>
            القسم
          </td>
        </tr>
        <tr>
          <td>
            {job}
          </td>
          <td>
            الوظيفة
          </td>
        </tr>
        <tr>
          <td>
            {salary}
          </td>
          <td>
            الراتب
          </td>
        </tr>
        <tr>
          <td>
            {bouns}
          </td>
          <td>
            المكافأة
          </td>
        <tr>
          <td>
            {extra}
          </td>
          <td>
            المبلغ الاضافي
          </td>
        </tr>
      </tbody>
     </table>
    </b-modal>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            user: JSON.parse(localStorage.getItem("user")),
            list: [],
            current: {},
            jobs: [],
            sections: [],
            header: "",
            body: "",
            footer: "",
            editor: ClassicEditor,
            editorData: '<p>Content of the editor.</p>',
            editorConfig: {
            language: {
                ui: 'ar',
                content: 'ar',
            }
            },
        }
    },
    created(){
        if(!checkPer('jobs')){
            this.$router.push('/pers')
            return;
        }
        this.getList()
        var g = this;
        $.post(api + '/admin/web/settings', {
            jwt: g.user.jwt
        }).then(function(r){
          g.jobs = (r.response.jobs ?? "").split(",")
          g.sections = (r.response.sections ?? "").split(",")
        })
    },
    methods: {
      printContract(){
        var g = this;
        var win = window.open("");
        win.document.write(`
        <html dir='rtl'>
          <head>
            <style>
              @page {
                  margin: 0;
              }
              h4{
                line-height: 1.8;
              }
              body{
                background-image: url("/images/contract.png") ;
                background-repeat:repeat-y;
                background-position: right top;
                background-attachment:fixed;
                background-size:100%;
              }
              .content{
                padding: 60px;
                padding-top: 130px;
                padding-bottom: 100px;}
                .page-break {
              page-break-before: always; /* Forces a new page with padding */
              padding-top: 130px; /* Ensures the top padding is repeated on new pages */
          }
            </style>
          </head>
          <body>
            <div class='content'>
              <center><h2>عقد عمل موسمي لحج عام ١٤٤٦ هـ</h2></center>
              <h4>${g.replaceVariables(g.current, g.header)}</h4>
              <h4>السيد/ ${g.current.name} 
              &nbsp;&nbsp;
              &nbsp;&nbsp;
              &nbsp;&nbsp;
              &nbsp;&nbsp;
              الجنسية/ ${g.current.content.national}</h4>
              <h4 style='padding: 5px; border-top: 0.5px solid #ddd; border-bottom: 0.5px solid #ddd;'>
                بموجب هوية رقم/ ${g.current.content.number}
                &nbsp;&nbsp;
                &nbsp;&nbsp;
                &nbsp;&nbsp;
                صادرة من/ ${g.current.content.number_source}
                &nbsp;&nbsp;
                &nbsp;&nbsp;
                &nbsp;&nbsp;
                وتاريخ الميلاد/ ${g.current.content.birth_date.replaceAll('-', '/')}
              </h4>
              <h4 style='padding: 5px; padding-top: 0px; margin-top: -10px; border-bottom: 0.5px solid #ddd;'>
                رقم الجوال/ 
                &nbsp;&nbsp;
                &nbsp;&nbsp;${g.current.phone}
              </h4>
              <h4>${g.replaceVariables(g.current, g.body)}</h4>
              <div class='page-break'>
                <h4>
                ${g.replaceVariables(g.current, g.footer)}
                </h4>
                <div style='display: inline-block; width: 49%'>
                  <h4 style='margin: 0px auto; text-align: center'>الطرف الأول</h4>
                  <h4 style='margin: 0px auto; text-align: center'>عمر عبدالعزيز عبدالله الشلاش</h4>
                  <h4>التوقيع:</h4>
                </div>
                <div style='display: inline-block; width: 49%'>
                  <h4 style='margin: 0px auto; text-align: center'>الطرف الثاني</h4>
                  <h4 style='margin: 0px auto; text-align: center'>${g.current.name}</h4>
                  <h4>&nbsp;&nbsp;&nbsp;التوقيع: ${g.current.signature ? "<img width='250' src='"+g.current.signature+"'>" : ''}</h4>
                </div>
              </div>
            </div>
          </body>
        </html>
        `)
        win.document.close()
        setTimeout(() => {
          win.print()
        }, 1000);
      },
      save(){
        var g = this;
        $.post(api + '/admin/forms/update-contract', {
            jwt: g.user.jwt,
            id: g.current._id,
            header: g.header,
            body: g.body,
            footer: g.footer
        }).then(function(r){
          alert("تم الحفظ بنجاح", 100)
        })
      },
      replaceVariables(current, input){
        for(var i=0; i<=20; i++){
          input = input.replace( /{(.*?)}/, function (x) {
          x = x.replace('{', '').replace('}', '')
          if(x == 'job'){
              if(current.job['code']){
                  return current.job['title'];
              }
          }
          if(current[x]){
            return current[x];
          }
          if(current.info[x]){
            return current.info[x];
          }
          if(current.content[x]){
            return current.content[x];
          }
          if(current.job[x]){
            return current.job[x];
          }
          return `{${x}}`;
        } )
        }
        return input
      },
      getList(){
        var g = this;
        $.post(api + '/admin/forms/accepted-list', {
            jwt: g.user.jwt,
        }).then(function(r){
            g.list = r.response.map(function(x){ 
              if(x.content){x.content = JSON.parse(x.content)};
              if(x.info){x.info = JSON.parse(x.info)}else{
                x.info = {}
              };
              return x;});
        })
      },
    }
}
</script>

<style>

</style>